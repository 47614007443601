import { InjectionToken } from '@angular/core';
import { CompaniesBranchesApiService } from '../companies-branches-api.service';

export const HTTP_COMPANY_BRANCHES_SERVICE = new InjectionToken(
  'CompaniesBranchesApiService'
);

export const COMPANIES_BRANCHES_API_PROVIDER = {
  provide: HTTP_COMPANY_BRANCHES_SERVICE,
  useClass: CompaniesBranchesApiService,
};
