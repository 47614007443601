import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ICompanyBranchApiService } from './company-branch-api.interface';
import { Observable, map, take } from 'rxjs';
import { CompanyBranchCreateOrUpdate } from '../domain/Company-branch-create-or-update.model';
import { CompanyBranch } from '../domain/Company-branch.model';
import { CompanyBranchApiModel } from './models/company-branch-api.model';
import { ResponseApi } from '../../../shared/infrastructure/models/response-api.interface';

@Injectable({
  providedIn: 'root',
})
export class CompaniesBranchesApiService implements ICompanyBranchApiService {
  private _http = inject(HttpClient);
  private readonly BASE_URL = environment.baseUrl;

  private _setMapCompanyBranch(
    companyBranch: CompanyBranchApiModel
  ): CompanyBranch {
    return {
      id: companyBranch.ID,
      name: companyBranch.name,
      idCompany: companyBranch.idCompany,
      createdAt: companyBranch.CreatedAt,
    };
  }

  private _setMapCompaniesBranches(
    companiesBranches: CompanyBranchApiModel[]
  ): CompanyBranch[] {
    return companiesBranches.map((companyBranch) => {
      return {
        id: companyBranch.ID,
        name: companyBranch.name,
        idCompany: companyBranch.idCompany,
        createdAt: companyBranch.CreatedAt,
      };
    });
  }

  getCompaniesBranches(idCompany: number): Observable<CompanyBranch[]> {
    const url = `${this.BASE_URL}companies/${idCompany}/branches`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.ok) return [];
        return this._setMapCompaniesBranches(
          response.results as CompanyBranchApiModel[]
        );
      })
    );
  }

  getCompanyBranch(
    idCompany: number,
    idBranch: number
  ): Observable<CompanyBranch | null> {
    const url = `${this.BASE_URL}companies/${idCompany}/branches/${idBranch}`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.ok) return null;
        return this._setMapCompanyBranch(
          response.results as CompanyBranchApiModel
        );
      })
    );
  }

  createCompanyBranch(
    idCompany: number,
    companyBranch: CompanyBranchCreateOrUpdate
  ): Observable<boolean> {
    const url = `${this.BASE_URL}companies/${idCompany}/branches`;
    const headers = new HttpHeaders();
    return this._http.post<ResponseApi>(url, companyBranch, { headers }).pipe(
      take(1),
      map((response) => {
        return response.ok;
      })
    );
  }

  updateCompanyBranch(
    idCompany: number,
    idCompanyBranch: number,
    companyBranch: CompanyBranchCreateOrUpdate
  ): Observable<CompanyBranch | null> {
    const url = `${this.BASE_URL}companies/${idCompany}/branches/${idCompanyBranch}`;
    const headers = new HttpHeaders();
    return this._http.put<ResponseApi>(url, companyBranch, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.ok) return null;
        return this._setMapCompanyBranch(
          response.results as CompanyBranchApiModel
        );
      })
    );
  }

  deleteCompanyBranch(
    idCompany: number,
    idCompanyBranch: number
  ): Observable<boolean> {
    const url = `${this.BASE_URL}companies/${idCompany}/branches/${idCompanyBranch}`;
    const headers = new HttpHeaders();
    return this._http.delete<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        return response.ok;
      })
    );
  }
}
